$theme-colors: (
  "primary": #059c00,
  "info": #06b500
);

.border-highlight {
    border-bottom: 5px solid map-get($theme-colors, "primary");
}

#main-nav-bar .active {
    border-bottom: 5px solid map-get($theme-colors, "primary");
}

.center-horizontally-and-vertically {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

@import "~bootstrap/scss/bootstrap";

